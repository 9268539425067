/* Login / Registration Forms
------------------------------------------*/
@media screen and (min-width: 991px) {
    .user-account-form .user-login-social .panel {
        min-height: 275px;
    }
    .user-account-form .panel-body {
        padding: 20px 30px;
    }
}
.user-account-form .panel-heading,
.user-login-blox .panel-heading,
.user-password-reset-wrapper .panel-heading {
    font-weight: bold;
}
.user-account-form .panel-footer {
    font-size: 95%;
}
.user-account-form .panel-footer.user-signup p + p {
    margin-bottom: 0px;
}
.user-account-form .user-login-facebook { margin-top: 20px; }
.user-account-form .user-login-social .btn {
    font-size: 16px;
    text-align: left;
    color: #fff;
    opacity: .95;
}
.user-account-form .user-login-social .btn:hover {
    opacity:1;
}
.user-account-form .user-login-social .btn .tnt-svg {
    margin-right: 4px;
}

.user-account-form .user-login-social .social-btn {
    margin-bottom: 10px;
}

.user-account-form .user-login-social .btn-appleid {
    background-color: #000;
}

.user-account-form .user-login-social .btn-facebook {
    background-color: #3A59A5;
}
.user-account-form .user-login-social .btn-google {
    background-color: #B83A3B;
}
.user-account-form .user-login-social .btn-yahoo {
    background-color: #500095;
}
.user-account-form .user-login-social .user-admin-login {
    padding-top: 7px;
}
.user-login-wrapper .user-login-form label {
    font-weight: normal;
}
#user-register-form fieldset {
    margin-bottom: 20px;
}
.user-register-wrapper .help-block {
    font-size: 95%;
    margin-bottom: 0px;
}
.user-register-wrapper .mailing-lists-select-all-container {
    margin-top: 10px;
}
.user-login-or {
    float: left;
    font-size: 20px;
    display: block;
    margin-right: 10px;
    font-style: italic;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.15);
    height: 40px;
    width: 50px;
    line-height: 40px;
    text-align: center;
}
.user-account-form .user-forgot-password,
.user-account-form .user-login-password,
.user-account-form .user-login-submit {
    margin-bottom:0;
}
.user-account-form .user-remember-br {
    opacity: .4;
    padding: 0 2px;
}
.user-account-form .user-signup-catpcha-text {
    width:120px;
    margin-left:15px;
}
.user-account-form .user-login-social .custom-btn {
    background-color: #999;
}
@media screen and (max-width: 767px) {
    .user-account-form .user-login-remember {
        font-size: 90%;
    }
    .user-login-or {
        float: none;
        margin: 0 auto;
        margin-bottom: 15px;
        height:50px;
        line-height: 50px;
        border-radius: 50px;
    }
}


/* User Profile
------------------------------------------*/
:root {
    --prof-spacing: 10px;
    --prof-lg-spacing: 20px;
}

#profile-container {
    margin-bottom: var(--prof-lg-spacing);
}

#profile-container .btn,
#profile-container .followed-notify-link {
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
}

#user-profile {
    font-size: 16px;
    margin-bottom: var(--prof-lg-spacing);
}

/* Profile main */
#profile-main {
    display: flex;
    justify-content: center;
    column-gap: var(--prof-spacing);
    align-items: center;
    text-align: center;
    margin-bottom: var(--prof-spacing);
}

#profile-main.has-photo {
    justify-content: space-between;
}

#profile-main.has-photo .details {
    width: 100%;
}

#profile-main .avatar img {
    max-width: 33vw;
    border-radius: 4px;
}

/* Filler profile avatar */
#profile-main .avatar svg.tnt-user {
    background: #c4c4c4;
    color: #fff;
    padding: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

#profile-main h1.name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
}

#profile-main .title {
    font-weight: bold;
    color: #595959;
    margin-top: var(--prof-spacing);
}

#profile-main .social-links {
    margin-top: var(--prof-spacing);
    margin-bottom: 0px;
}

/* Profile actions */
#profile-actions {
    display: flex;
    justify-content: space-between;
    column-gap: var(--prof-spacing);
    margin-bottom: var(--prof-lg-spacing);
}

/* Profile detail */
#profile-detail .card {
    border-top: 5px solid #efefef;
    padding: var(--prof-lg-spacing);
    margin-bottom: 0px;
}

#profile-detail .card .title {
    font-size: 20px;
}

/* User content */
#profile-content .media.comment .avatar {
    width: 40px;
}

/* User comments avatar */
#profile-content .media.comment .avatar {
    width: 40px;
}

/* User comments filler avatar */
#profile-content .media.comment .tnt-svg {
    background: #c4c4c4;
    color: #fff;
    font-size: 32px;
    padding: 4px 0;
    width: 40px;
}

/** Desktop profile */
@media (min-width: 768px) {
    #profile-main,
    #profile-main.has-photo {
        flex-direction: row-reverse;
        justify-content: center;
        column-gap: var(--prof-lg-spacing);
    }
    
    #profile-main {
        text-align: right;
        margin-bottom: var(--prof-lg-spacing);
    }
    
    #profile-main.has-photo .details {
        width: auto;
    }
    
    #profile-main h1.name {
        font-size: 2em;
    }
    
    #profile-main .title {
        font-size: 1.2em;
    }
    
    #profile-actions,
    #view-all.btn {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    
    #view-all.btn {
        display: block;
        margin-bottom: var(--prof-lg-spacing);
    }
    
    #profile-detail .card .title {
        font-weight: bold;
    }
    
    #user-profile .avatar img {
        max-width: max-content;
    }
    
    #profile-detail {
        display: flex;
        flex-wrap: wrap;
        border-top: 5px solid #efefef;
        max-width: 700px;
        margin: auto;
    }
    
    .main-content.has-rail #profile-detail {
        max-width: 100%;
    }
    
    #profile-detail .card {
        flex: 50%;
        border: 0px;
    }
    
    #profile-detail #user-about {
        flex: 0 0 100%;
    }
    
    /* User content posts */
    #posts .card-grid {
        display: grid;
        gap: 5px 30px;
        word-break: break-word;
        grid-template-columns: repeat(3, 1fr);
    }
}

/* User General
------------------------------------------*/
.user-header h2 {
    margin: 0;
}
.users-sidebar .panel-group .panel+.panel {
    margin-top: 10px;
}
.users-sidebar .panel-heading a {
    display: block;
}
.users-sidebar .panel-body {
    padding: 0;
}
.users-sidebar .panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 1px;
}

.users-sidebar .sub-item {
    padding-left: 25px;
}
.users-sidebar .panel-title a {
    display: block;
    padding-right: 20px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.users-sidebar .caret-container {
    position: absolute;
    top: 0px;
    right: 0px;
}
#user-main-menu-wrapper .nav-pills {
    margin-bottom: 10px;
}

/* single item styles */
.users-sidebar .panel.single .panel-heading {
    padding: 0;
}
.users-sidebar .panel.single a {
    border: none;
}

@media(max-width:991px){
    .contrib-tools {
        width: auto;
    }
    .contrib-tools .btn {
        width: auto;
        text-align: center;
    }
    .card.event.event-user-dash {
        padding: 15px 47px 15px 15px;
    }
}

/* Dashboard Page
------------------------------------------*/
.user-dashboard .dash-tile i,
.user-dashboard .dash-tile .tnt-svg {
    font-size: 32px;
}
.user-dashboard .dash-tile p {
    margin-bottom: 0px;
}
.user-dashboard .dash-tile {
    text-align: center;
}
.user-dashboard .dash-tile .panel {
    width: 100%;
}
.user-dashboard .dash-tile a {
    color: inherit;
}
.user-dashboard .dash-tile a:hover {
    text-decoration: none;
}
.user-dashboard .dash-tile a:hover .panel-body,
.user-dashboard .dash-tile a:focus .panel-body {
    background-color: #F0F0F0;
}
@media screen and (min-width: 768px) {
    .user-dashboard .dash-tiles,
    .user-dashboard .dash-tile,
    .user-dashboard .dash-tiles .panel {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
    .user-dashboard .dash-tiles a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .user-dashboard .dash-tiles .panel-body{
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
}

/* Subscription
------------------------------------------*/
.service-radio {
    width: 48px;
    text-align: center;
    vertical-align: middle;
}
.service-radio input {
    width: 18px;
    height: 18px;
    margin-top: 11px;
}
.service-label {
    font-weight: normal;
    margin-bottom: 0;
}
@media (min-width: 768px) {
    .service-radio {
        width: 64px;
    }
}
.form-review-label {
    text-align: right;
}
.form-review-product-title {
    margin-top: 0;
}
.form-review-row {
    border-top: 1px solid #DDD;
    padding: 8px;
}
.form-review-row:nth-child(odd) {
    background: #F9F9F9;
}
.form-review-legend {
    margin-top: 25px;
    margin-bottom: 0;
    padding-bottom: 2px;
}
.form-review-row:first-child {
    border-top: none;
}
.form-review-fieldset {
    margin-bottom: 15px;
}
@media (max-width:767px) {
    .form-review-label {
        text-align: left;
    }
}

#subscribeForm .tax-result {
    font-weight: bold;
    font-size: 18px;
    color: #010101;
    margin-top:15px;
    margin-bottom: 15px;
}

#tax-subtotal-container {
    margin-right: 30px;
}

.service-purchase-steps {
    color: rgba(0,0,0,0.4);
}
.service-purchase-steps .active span {
    color: rgba(0,0,0,0.8);
    font-weight: bold;
}
.step-terms .list-group-item {
    display: flex;
    align-items: center;
}
.step-terms .list-group-item.promo-rate {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #c7d8c1;
}
.step-terms .list-group-content {
    margin-left: 15px;
}
.step-terms .list-group-item-text {
    line-height: 1.42857143;
}
.step-terms label.list-group-item-heading {
    font-size: 18px;
    font-weight: normal;
}
.step-terms .list-group-item.active,
.step-terms .list-group-item.active:hover,
.step-terms .list-group-item.active:focus {
    color: #fff;
    background-color: #305b80;
    border-color: #327bba;
}
.step-terms .list-group-item:not(.active):hover,
.step-terms .list-group-item:not(.active):focus {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.service-container .service-heading .panel-title {
    display: flex;
    justify-content: space-between;
}
.service-container .service-image img {
    max-width: 100%;
}
.service-container .service-total {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

#subscribeForm {
    margin-bottom: 20px;
}

.alert {
    box-shadow: none;
    border-width: 0 0 0 5px;
    padding-left: 25px;
}

.alert-warning {
    border-color: #8a6d3b;
}

.alert-info {
    border-color: #31708f;
}

.service-text {
    border: 10px solid #efefef;
    padding: 25px 25px 15px;
    margin-bottom: 20px;
}

#service-promo-open .btn-primary {
    background-color: #e74c3c;
    border: 1px #e74c3c solid;
    border-radius: 0 5px 5px 0;
}

.service-rates label.border-top {
    border: 10px solid transparent;
    padding: 15px;
}

.service-rates label:first-child {
    border-color: #efefef;
}

#subscribeForm.form-horizontal #subscribeForm-submit {
    width: 100%;
}

@media (min-width: 768px) {
    #subscribeForm.form-horizontal #subscribeForm-submit {
        width: auto;
    }
}

#subscribeForm-submit.btn-default,
.service-sublink .btn-primary {
    background-color: #e74c3c;
    color: #fff;
    box-shadow: 0px 5px 0px 0px #ce3323;
    border: 0px;
    border-radius: 5px;
    padding: 10px 25px;
    font-size: 22px;
    font-weight: normal;
    line-height: normal;
}

#service-promo-open .btn-primary:hover,
.service-sublink .btn-primary:hover,
#subscribeForm-submit:hover,
.btn-primary:active {
    background-color: #ff6656;
}

.btn-primary:active {
    outline: none;
}

.subscription-service.panel {
    border: 10px solid #efefef;
    box-shadow: none;
}

#user-main-menu-wrapper .panel {
    box-shadow: none;
}

legend, 
.user-header h2 {
    font-weight: bold;
    margin-top: 20px;
    color: #222;
}

legend,
.subscription-service.panel-default > .service-heading {
    background: none;
    color: #003471;
}

legend,
.block-title, 
.user-header,
.user-header .user-header-inner {
    border: none;
}

.subscription-service.panel-default > .service-heading .panel-title {
    font-size: 30px;
}

.subscription-services .panel-body {
    padding-top: 0px;
}

.subscription-services .panel-footer {
    margin: 10px;
}

/* Mailinglist
------------------------------------------*/

.mailinglist-container .mailinglist-instructions {
    margin-bottom: 20px;
}

.mailinglist-container .mailing-lists {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

@supports (display:grid) {
    .mailinglist-container .mailing-lists {
        display: grid;
    }
}

.mailinglist-container .mailinglist-item {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
}

.mailinglist-container .mailinglist-item.no-icon {
    display: block;
}

.mailinglist-container .mailinglist-item:after {
    position: absolute;
    width: 100%;
    height: 1px;
    z-index: -1;
    border-top: 1px dashed #ccc;
}

.mailinglist-container .mailinglist-item:before {
    position: absolute;
    width: 1px;
    height: 100%;
    z-index: -1;
    border-left: 1px dashed #ccc;
}

/* Details */
.mailinglist-container .mailinglist-details h4 {
    margin-top: 5px;
}

.mailinglist-container .mailinglist-details label {
    cursor: pointer;
    margin-bottom: 0;
}

/* Footer */
.mailinglist-container .mailinglist-footer {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 10px;
}

@media (min-width: 480px) {
    .mailinglist-container .mailinglist-footer {
        flex-direction: row;
        gap: 18px;
    }
}

@media (min-width: 1200px) {
    .mailinglist-container .mailinglist-footer {
        flex-direction: row;
        gap: 18px;
    }
}

/* Switch */
@supports (-webkit-appearance:none) or (-moz-appearance:none) {
    .mailinglist-container .switch:after {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        /*background: #BBC1E1;*/
        background: #C0C0C0;
        transform: translateX(0);
        transition: transform 0.3s ease, opacity 0.2s;
    }

    .mailinglist-container .switch {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        margin: 0;
        width: 38px;
        border-radius: 11px;
        cursor: pointer;
        border: 1px solid #C0C0C0;
        background: #fff;
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

    .mailinglist-container .switch:checked {
        background: #305b80;
        border: 1px solid #305b80;
    }

    .mailinglist-container .switch:checked:after {
        background: #fff;
        transform: translateX(17px);
    }

    .mailinglist-container .switch:hover:not(:checked):not(:disabled) {
        border-color: #305b80;
    }

    .mailinglist-container .switch:focus {
        box-shadow: 0 0 0 2px rgba(48, 91, 128, 0.3);
        outline: none;
    }
}

.mailinglist-container .mailinglist-frequency {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.mailinglist-container .mailinglist-frequency i,
.mailinglist-container .mailinglist-frequency svg {
    color: #777;
}

.mailinglist-container #mailinglist-save .tnt-spinner {
    display: none;
    margin-left: 5px;
}

@media (max-width: 649px) {
    .mailinglist-container .mailinglist-item {
        position: relative;
    }

    .mailinglist-container .mailinglist-item:nth-child(n+2):after {
        content: '';
        top: -16px;
    }

    .mailinglist-container .mailinglist-item:last-child {
        padding-bottom: 16px;
    }
}

@media (min-width: 650px) and (max-width: 991px) {
    .mailinglist-container .mailinglist-item {
        display: block;
        grid-template-columns: auto;
        gap: 10px;
    }
}

@media (min-width: 650px) {
    .mailinglist-container .mailing-lists {
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;
    }

    .mailinglist-container .mailinglist-item:nth-child(1):before {
        content: '';
        height: calc(100% - 20px);
        top: 10px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .mailinglist-container .mailinglist-item:nth-child(even) {
        position: relative;
    }

    .mailinglist-container .mailinglist-item:nth-child(even):after {
        content: '';
        left: calc(-100% - 48px);
        width: calc(200% + 48px);
        top: calc(100% + 24px);
    }

    .mailinglist-container .mailinglist-item:last-child {
        padding-bottom: 24px;
    }
}

@media (min-width: 992px) {
    .mailinglist-container .mailinglist-item {
        gap: 20px;
    }
}

.mailinglist-container .mailinglist-item:last-child:after {
    content: none;
}


/* Edit Profile
------------------------------------------*/
#profile-form .avatar-container {
    align-content: center;
    text-align: center;
}
#profile-form .avatar-container img {
    width: 100%;
    max-width: 50px;
    margin:0 auto;
}
#profile-form .help-block {
    margin-bottom: 0px;
}

/* Allow entire date input field to trigger the native datepicker */
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* OTT connected devices
------------------------------------------*/
.user-dashboard .ott-remove.tnt-grid {
    display: grid;
    grid-template-columns: auto 110px;
    margin-bottom: 20px;
}

/* Contribute
------------------------------------------*/
.user-contribute-tools > a{ margin: 0 5px; }
.user-business-list .media .media-thumb, .user-contribute-list .media .media-thumb{ width: 33%;}
.user-business-list .media .media-left, .user-contribute-list .media .media-left{ float: left; margin-right: 5%; }
.user-business-list .media .media-right, .user-contribute-list .media .media-right{ float: right; margin-left: 5%; }
.user-business-left .media .media-body, .user-contribute-list .media .media-body{ width: 62%; }
.note-editor.panel{ margin-bottom:5px; }
.note-popover .popover-content, .panel-heading.note-toolbar{ border-bottom-width:1px; border-bottom-style:solid; }


/* Business
------------------------------------------*/
#business-form .logo-container img{ margin: auto; }


/* Calendar
------------------------------------------*/
span.required,
label.has-error,
span.has-error {
    color: #A94442;
}

span.has-error {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.occur-items {
    display: none;
}
.recur-separator {
    margin: 25px 0;
    height: 1px;
    background: rgba(0,0,0,.1);
    text-align: center;
}
.recur-separator span {
    position: relative;
    top: -10px;
    background: #F5F5F5;
    padding: 0 5px;
    font-weight: bold;
    text-transform: uppercase;
}
.schedule .form-group:last-child,
.schedule .well:last-child {
    margin-bottom: 0;
}
.schedule .occur-items {
    margin-top: 15px;
}
@media (max-width: 767px) {
    .schedule .start-time {
        margin-bottom: 10px;
    }
}
#biz-iframe-holder .modal-body {
    padding: 15px 15px 15px 0;
}
#biz-iframe-holder .embed-responsive {
    height: calc(85vh - 116px);
    overflow: auto;
}


/* Linked Accounts
------------------------------------------*/
.user-linked-accounts .user-linked-accounts-group {
    margin-bottom: 30px;
}
.user-linked-accounts .access-grant-input,
.user-linked-accounts .access-request-input {
    margin-bottom: 10px;
}
@media (min-width: 768px) {
    .user-linked-accounts .access-grant-input,
    .user-linked-accounts .access-request-input {
        margin-bottom: 0px;
    }
}


/* User Action Items
------------------------------------------*/
.user-action-item {
    border-bottom: 1px solid #DDD;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.user-action-item .state-update {
    display: none;
}
.user-action-item .btn-block.state-update,
.user-action-item .btn-block.state-default {
    margin-top: 0px;
}
.user-action-item .text-title {
    padding: 7px 0;
}
.user-action-item .input-title,
.user-action-item .text-title {
    margin-bottom: 5px;
}
.user-action-item .item-tools {
    margin-left: -2px;
    margin-right: -2px;
}
.user-action-item .item-tools > div {
    width: 50%;
    float: left;
    padding: 0px 2px;
}
.user-action-item .item-tools > div.item-one-button {
    float: right;
    width: 100%;
}
@media (min-width: 768px) {
    .user-action-item .input-title,
    .user-action-item .text-title {
        margin-bottom: 0px;
    }
    .user-action-item .item-tools > div.item-one-button {
        width: 50%;
    }
}


/* Submit / Edit Business Form
------------------------------------------*/
#business-form .typeahead-wrapper {
    position: relative;
}
#business-form .typeahead-hint {
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    opacity: 1;
}
#business-form .form-control[readonly].typeahead-hint {
    background-color: #FFF;
    color: #999;
}
#business-form .typeahead-input {
    position: relative;
    vertical-align: top;
    background-color: transparent;
}
#business-form .typeahead-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    display: none;
}
#business-form .typeahead-menu.is-open {
    display: block;
}
#business-form .typeahead-menu.is-empty {
    display: none;
}
#business-form .typeahead-menu {
    width:100%;
    max-height: 250px;
    overflow-y:scroll;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}
#business-form .tt-suggestion {
    padding: 3px 20px;
}
#business-form .tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #0097cf;
}
#business-form .tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #0097cf;
}
#business-form .tt-suggestion p {
    margin: 0;
}
#business-form .selected-cat-container {
    padding-bottom: 4px;
}
#business-form .selected-cat-number {
    display: inline-block;
    line-height: 1;
    padding: .3em .3em .3em 0;
}
#business-form .selected-cat-content {
    display: inline-block;
}
#business-form .item-bdc {
    display: none;
}
#business-form .bdc-dismiss {
    cursor: pointer;
}
#business-form .niche-fieldset {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px dashed #ccc;
}
#business-form .niche-fieldset > h3 {
    margin-bottom: 30px;
}

/* Business Layout Form
------------------------------------------*/
#business-layout-form .color-picker-input {
    padding: 0;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    border-radius: 2px;
    background-color: transparent;
}
#business-layout-form .color-picker-input::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 0;
}
#business-layout-form .color-picker-input::-webkit-color-swatch {
    border: 0;
}
#business-layout-form svg {
    pointer-events: none;
}
#business-layout-form .navitem-name {
    width: 50%;
}
#business-layout-form .navitem-url {
    width: calc(50% + 1px);
    margin-left: -1px;
}

/* NowApp Styles
------------------------------------------*/
.user-nowapp-padding {
    padding: 20px 15px;
}

/* Print Styles
------------------------------------------*/
@media print {
    .users-sidebar {
        display: none;
    }
}

/* Web authentication (Easy Login)
------------------------------------------*/
#webauthn-register,
#webauthn-active,
#webauthn-credentials-container {
    margin-bottom: 40px;
}

.btn.btn-webauthn {
    background: rgb(173 0 0);
    border: 1px solid rgb(153, 0, 0);
    border-radius: 4px;
    color: #fff;
}

.btn.btn-webauthn.social-btn {
    border-radius: 0;
}

.btn.btn-webauthn:hover {
    background: rgb(193, 0, 0);
    color: #fff;
}

.btn.btn-webauthn.btn-full-xs {
    display: block;
    margin: auto;
    width: 80%;
}

@media (min-width: 768px) {
    .btn.btn-webauthn.btn-full-xs {
        width: auto;
        margin: 0;
    }
}